import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import Dutch from './lang/nl.json';
import French from './lang/fr.json';
import English from './lang/en.json';
import Norwegian from './lang/no.json';
import Danish from './lang/dk.json';

import ChakraTheme from "./templates/Chakra";
import AlvionaTemplate from "./templates/Alviona";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Step9 from "./pages/step9/Step9";
import Step10 from "./pages/step10/Step10";
import Step11 from "./pages/step11/Step11";
import Step12 from "./pages/step12/Step12";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

import success2 from "./assets/step4/success2.gif";
import useImagePreloader from "./hooks/UseImagePreloader";
import Step3Extra from "./pages/step3-extra/Step3Extra";

function App() {
   const currentUrl = window.location.toString();

    const preloadSrcList = [
        success2
    ]

    useImagePreloader(preloadSrcList)

    let messages = German;
    let locale = 'de';
    moment.locale('de');

    let trust7Locale = 'de-DE';
    let trust7Channel = 'alviona:de';

    switch (true) {
        case (currentUrl.search('/nl') !== -1):
            messages = Dutch;
            locale = 'nl';
            moment.locale('nl');

            trust7Locale = 'nl-NL';
            trust7Channel = 'alviona:nl';
            break;

        case (currentUrl.search('/fr') !== -1):
            messages = French;
            locale = 'fr';
            moment.locale('fr');

            trust7Locale = 'fr-FR';
            trust7Channel = 'alviona:fr';
            break;

        case (currentUrl.search('/uk') !== -1):
            messages = English;
            locale = 'en';
            moment.locale('en');

            trust7Locale = 'en-GB';
            trust7Channel = 'alviona:en';
            break;

        case (currentUrl.search('/no') !== -1):
            messages = Norwegian;
            locale = 'no';
            moment.locale('nb');

            trust7Locale = 'nb-NO';
            trust7Channel = 'alviona:no';
            break;

        case (currentUrl.search('/dk') !== -1):
            messages = Danish;
            locale = 'dk';
            moment.locale('da');

            trust7Locale = 'da-DK';
            trust7Channel = 'alviona:dk';
            break;

        case (currentUrl.search('/ie') !== -1):
            messages = English;
            messages.outgoingUrl = 'https://buyalviona.com/ie/buy-now.html';
            locale = 'en-ie';
            moment.locale('en');

            trust7Locale = 'en-IE';
            trust7Channel = 'alviona:en';
            break;

        case (currentUrl.search('/befr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://buyalviona.com/befr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');

            trust7Locale = 'fr-FR';
            trust7Channel = 'alviona:fr';
            break;

        case (currentUrl.search('/benl') !== -1):
            messages = Dutch;
            messages.outgoingUrl = 'https://buyalviona.com/benl/nu-kopen.html';
            locale = 'nl';
            moment.locale('nl');

            trust7Locale = 'nl-NL';
            trust7Channel = 'alviona:nl';
            break;
        //
        // case (currentUrl.search('/lude') !== -1):
        //     messages.outgoingUrl = 'https://buyalviona.com/lude/jetzt-kaufen.html';
        //     break;
        //
        // case (currentUrl.search('/lufr') !== -1):
        //     messages = French;
        //     messages.outgoingUrl = 'https://buyalviona.com/lufr/acheter-maintenant.html';
        //     locale = 'fr';
        //     moment.locale('fr');
        //     break;

        case (currentUrl.search('/at') !== -1):
            messages.outgoingUrl = 'https://buyalviona.com/at/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Wien';
            messages['step10.headline'] = 'Svenja aus Graz';
            break;

        case (currentUrl.search('/ch') !== -1):
            messages.outgoingUrl = 'https://buyalviona.com/ch/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Basel';
            messages['step10.headline'] = 'Svenja aus Zürich';
            locale = 'de-ch';
            break;

        default:
    }

    // load trust7 widget sdk and ensure it is only loaded once
    if (!document.getElementById('trust7-sdk')) {
        const trust7 = document.createElement('script');
        trust7.setAttribute('id', 'trust7-sdk');
        trust7.setAttribute('type', 'module');
        trust7.setAttribute('src', `https://elements.trust7.app/${trust7Locale}/widget-elements.js`);
        document.head.appendChild(trust7);
    }

    document.title = messages.title;

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <AlvionaTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2/>
                            }/>
                            <Route path='/step3' element={
                                <Step3/>
                            }/>
                            <Route path='/step3-extra' element={
                                <Step3Extra/>
                            }/>
                            <Route path='/step4' element={
                                <Step4/>
                            }/>
                            <Route path='/step5' element={
                                <Step5/>
                            }/>
                            <Route path='/step6' element={
                                <Step6/>
                            }/>
                            <Route path='/step7' element={
                                <Step7/>
                            }/>
                            <Route path='/step8' element={
                                <Step8/>
                            }/>
                            <Route path='/step9' element={
                                <Step9/>
                            }/>
                            <Route path='/step10' element={
                                <Step10/>
                            }/>
                            <Route path='/step11' element={
                                <Step11/>
                            }/>
                            <Route path='/step12' element={
                                <Step12/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete channel={trust7Channel}/>
                            }/>
                        </Routes>
                    </AlvionaTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
